import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`../../assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Документи',
    path: '/',
    icon: icon('spravki'),
  },
  {
    title: 'Добави нов паспорт',
    path: '/add-passport',
    icon: icon('tablo'),
  },
  {
    title: 'Потребители',
    path: '/users',
    icon: icon('tablo'),
  },
];

export default navConfig;
