import { lazy } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

export const IndexPage = lazy(() => import('../pages/app'));
export const LoginPage = lazy(() => import('../pages/login'));

export const AddPassportPage = lazy(() => import('../pages/add-passport'));
export const EditPassportPage = lazy(() => import('../pages/edit-passport'));
export const AddUserPage = lazy(() => import('../pages/add-user'));
export const UsersPage = lazy(() => import('../pages/users'));

export const SinglePassportPage = lazy(() => import('../pages/single-passport'));

export const Page404 = lazy(() => import('../pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
    
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <PrivateRoute
          element={
            <Outlet />
          }
        />
      ),
      children: [
        { element: <IndexPage />, index: true },
        { element: <AddPassportPage />, path: 'add-passport' },
        { element: <EditPassportPage />, path: 'edit-passport/:id' },
        { element: <AddUserPage />, path: 'add-user' },
        { element: <UsersPage />, path: 'users' },
      ],
    },
    {
      path: 'single-passport/:id',
      element: <SinglePassportPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
