import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import usersReducer from './features/users';
import filesReducer from './features/files';
import passportReducer from './features/passports';
import guestReducer from './features/guest';

// Persist config for the users reducer
const usersPersistConfig = {
  key: 'users',
  storage,
};

// Persisted users reducer
const persistedUsersReducer = persistReducer(usersPersistConfig, usersReducer);

// Combine the reducers
const reducers = combineReducers({
  users: persistedUsersReducer,
  files: filesReducer,
  passports: passportReducer,
  guest: guestReducer,
});


const rootReducer = (state, action) => {
  if (action.type === 'users/logoutUser') {
    // Clean up state on logout
    state = undefined;
    localStorage.removeItem('token');
  }
  return reducers(state, action);
};

// Create the store with the root reducer
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore these field paths in actions
        ignoredActionPaths: ['register', 'rehydrate'],
        // Ignore these paths in the state
        ignoredPaths: ['_persist'],
      },
    }),
});


// Create the persistor
const persistor = persistStore(store);

export { store, persistor };