import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    fileUrls: {},
    fileId: {},
    loading: false,
    error: null
};

export const uploadFile = createAsyncThunk(
    'fileUpload/uploadFile',
    async (fileData, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', fileData.file);

            const response = await api.post('/upload/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { fileUrl: response.data.fileUrl, materialType: fileData.materialType, fileId: response.data.id };
        } catch (err) {
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const uploadFileReinforcement = createAsyncThunk(
    'fileUpload/uploadFileReinforcement',
    async (fileData, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', fileData.file);

            const response = await api.post('/upload/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { fileUrl: response.data.fileUrl, index: fileData.index, fileId: response.data.id };
        } catch (err) {
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

export const uploadFileFillingParts = createAsyncThunk(
    'fileUpload/uploadFileFillingParts',
    async (fileData, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', fileData.file);

            const response = await api.post('/upload/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { fileUrl: response.data.fileUrl, index: fileData.index, certificate: fileData.cert, fileId: response.data.id };
        } catch (err) {
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
); 

export const singleFileUpload = createAsyncThunk(
    'fileUpload/singleFileUpload',
    async (fileData, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', fileData.file);

            const response = await api.post('/upload/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { fileUrl: response.data.fileUrl, fileId: response.data.id, type: fileData.type };
        } catch (err) {
            return rejectWithValue(err.response ? err.response.data : err.message);
        }
    }
);

const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        resetFilesState: (state) => {
            state.fileUrls = {};
            state.fileId = {};
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFile.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.loading = false;
                state.fileUrls[action.payload.materialType] = action.payload.fileUrl;
                state.fileId[action.payload.materialType] = action.payload.fileId;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(uploadFileReinforcement.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadFileReinforcement.fulfilled, (state, action) => {
                state.loading = false;
                state.fileUrls[action.payload.index + '-reinforcement'] = action.payload.fileUrl;
                state.fileId[action.payload.index + '-reinforcement'] = action.payload.fileId;
            })
            .addCase(uploadFileReinforcement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(uploadFileFillingParts.pending, (state) => {
                state.loading = true;
            })
            .addCase(uploadFileFillingParts.fulfilled, (state, action) => {
                state.loading = false;
                state.fileUrls[action.payload.index + '-fillingParts' + '-' + action.payload.certificate] = action.payload.fileUrl;
                state.fileId[action.payload.index + '-fillingParts' + '-' + action.payload.certificate] = action.payload.fileId;
            })
            .addCase(uploadFileFillingParts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(singleFileUpload.pending, (state) => {
                state.loading = true;
            })
            .addCase(singleFileUpload.fulfilled, (state, action) => {
                state.loading = false;
                state.fileUrls[action.payload.type] = action.payload.fileUrl;
                state.fileId[action.payload.type] = action.payload.fileId;
            })
            .addCase(singleFileUpload.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { resetFilesState } = filesSlice.actions;
export default filesSlice.reducer;