import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'
import { resetFilesState } from './files';

// Define the initial state
const initialState = {
    passports: [],
    passport: null,
    loading: false,
    error: null,
};

export const addPassport = createAsyncThunk(
    'passports/addPassport',
    async ({ passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates }, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.post('/passport/add-passport',
                { passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates });
            dispatch(resetFilesState());
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const saveAsNewPassport = createAsyncThunk(
    'passports/saveAsNewPassport',
    async ({ passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates }, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.post('/passport/save-as-new',
                { passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates });
            dispatch(resetFilesState());
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getPassports = createAsyncThunk(
    'passports/getPassports',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/passport/get-passports');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getPassportById = createAsyncThunk(
    'passports/getPassportById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/passport/get-passport/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const deletePassport = createAsyncThunk(
    'passports/deletePassport',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/passport/delete-passport/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const updatePassport = createAsyncThunk(
    'passports/updatePassport',
    async ({ id, passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates }, { dispatch, rejectWithValue }) => {
        try {
            const response = await api.put(`/passport/update-passport/${id}`,
                { passportData, concreteMaterials, reinforcements, tensionReinforcements, fillingParts, electrods, features, manufacturingData, certificates });
            dispatch(resetFilesState());  
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

const passportsSlice = createSlice({
    name: 'passports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addPassport.pending, (state) => {
                state.loading = true;
            })
            .addCase(addPassport.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addPassport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getPassports.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPassports.fulfilled, (state, action) => {
                state.loading = false;
                state.passports = action.payload.passports;
            })
            .addCase(getPassports.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getPassportById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPassportById.fulfilled, (state, action) => {
                state.loading = false;
                state.passport = action.payload.passport;
            })
            .addCase(getPassportById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deletePassport.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePassport.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deletePassport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updatePassport.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePassport.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updatePassport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default passportsSlice.reducer;
